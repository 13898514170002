@import '../../variables.scss';
.contacts {
    font-family: Arial, Helvetica, sans-serif;
    background-color: $blue-background;
    display: flex;
    margin-bottom: 75px;
    &__map-mock {
        height: 633px;
        width: 42%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__info {
        font-size: 13px;
        padding: 20px 25px;
        max-width: 600px;
        .bold-text {
            font-weight: bold;
            font-size: 15px;
        }
    }
}