/* VARIABLES */

// Font sizes
$large-title-font-size: 36px;
$medium-title-font-size: 18px;
$large-label-font-size: 25px;
$medium-label-font-size: 13px;
$medium-label-mini-font-size: 12px;
$input-medium-font-size: 13px;
// Colors
$main-color: #46a6af;
$main-dark-color: #017698;
$main-dark-color-hover: #117cb9;
$main-border-color: #95989a;
$modal-background-color: #f5fcfd;
$main-button: #46a6af;
$main-button-hover: #4fb7c0;
$main-dark-button: #017698;
$main-dark-button-hover: #117cb9;
$blue-background: #cce0e3;
$text-color: #49414c;
$blue-main: #0279bd;
$green-main: #7eccc4;
$button-blue: #46a6af;
$button-blue-hover: #4fb7c0;
$light-button-text: #2a848c;
$button-dark-blue: #045f94;
$button-dark-blue-hover: #117cb9;
$auth-button: #eef2f8;
$auth-button-hover: #dde7f7;
$close-color: #c7c7c7;
$close-hover-color: #a09f9f;
$input-border-color: #c8c6c7;
$error-color: #f12626;
$error-border-color: #c72121;
// Sizes
$auth-button-height: 33px;
$input-height: 33px;
$mini-input-height: 26px;
$textarea-height: 74px;
// Margins
$modal-footer-margin-top: 24px;
$blocks-margin: 24px;
$standart-margin-bottom: 8px;
// Paddings
$standart-padding: 32px;

/* ---------------------------- */