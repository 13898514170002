@import '../../variables.scss';
.no-result {
    padding: 32px 0;
}

.extra-filters {
    &__filter {
        padding: 0 4px;
        align-self: center;
        display: table-cell;
    }
    &__row {
        display: inline-flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        width: 80%;
    }
    &__column {
        display: flex;
        flex-direction: row;
        width: 50%;
        min-width: 300px;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 4px;
        .form-label {
            width: 130px;
            text-align: end;
            vertical-align: center;
            margin-bottom: 0;
            padding-right: 8px;
            margin-left: 16px;
        }
        select {
            font-size: 13px;
        }
    }
    &__accordeon {
        text-decoration: underline;
        cursor: pointer;
        text-underline-position: under;
        transition: all 0.5s;
        font-size: 13px;
    }
    &__label {
        color: $main-dark-color;
        text-align: center;
        margin-top: 8px;
        margin-bottom: -24px;
    }
    &__result {
        display: inline-block;
        margin: 0 4px;
        border-radius: 2px;
        font-size: 13px;
        img {
            cursor: pointer;
            width: 10px;
            height: 10px;
            margin: 0 4px;
        }
    }
    &__filters {
        width: 100%;
        margin-bottom: 8px;
    }
    &__wrapper {
        padding-top: 16px;
        padding-bottom: 24px;
        text-align: center;
        font-size: 13px;
        width: 908px;
        margin: auto;
    }
    label {
        display: table-cell;
    }
}

.filter-form-group {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    width: 100%;
    label {
        width: 25%;
        padding: 0 16px;
        margin: 0;
        align-self: center;
    }
}