@import '../../variables.scss';
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 86px;
}

.feature-container:hover span {
    text-decoration: underline;
}

.feature-image {
    margin-bottom: 10px;
}

.feature-desc {
    color: #3d343f;
    font-size: 13px;
    text-align: center;
    line-height: 14px;
}

.feature-desc span {
    color: $main-dark-color;
}

.feature-desc span:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $main-dark-color;
}


/* --------------- */


/* --------------- */

.reviews-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reviews-nav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.review-item {
    font-size: 13px;
    margin-bottom: 0;
    padding: 0 48px;
    text-align: center;
}

.carousel-control-prev,
.carousel-control-next {
    display: none !important;
}

.cls-1 {
    //fill: $blue-background !important;
}


/* --------------- */


/* --------------- */

.counter-block-wrapper {
    width: 910px;
    margin-top: 40px;
}

.counter-block {
    margin-top: 30px;
    background-color: #ebebeb;
    padding: 39px 130px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
}

.counter-item {
    max-width: 120px;
}

.counter-title {
    color: $main-dark-color;
    font-size: 29px;
    text-align: center;
    line-height: 1;
    margin-bottom: 7px;
}

.counter-desc {
    text-align: center;
    font-size: 11px;
    margin-bottom: 0;
    line-height: 1;
}


/* --------------- */


/* --------------- */

.content-block {
    width: 910px;
    margin-top: 40px;
}

.mobile-app-block {
    background-color: #ebebeb;
    padding: 50px 30px 0 30px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    max-width: 950px;
}

.app_icon {
    width: 192px;
    height: 85px;
}

.mobile-app-title {
    color: $main-dark-color;
    font-size: 30px;
    margin-top: 10px;
}

.mobile-app-desc {
    font-size: 20px;
    font-weight: bold;
    color: $main-dark-color;
    margin-top: 45px;
}

.qr-code-img {
    width: 130px;
    height: 130px;
    margin-top: 20px;
    float: left;
}

.app-images-block {
    display: flex;
    margin-top: 56px;
}

.app-store {
    margin-left: 38px;
    width: 110px;
    height: 45px;
    cursor: pointer;
}

.google-play {
    margin-left: 31px;
    width: 150px;
    height: 60px;
    cursor: pointer;
}


/* --------------- */

.mobile-app-right {
    width: fit-content;
    margin: 0;
}

.app_img1 {
    width: 204px;
    height: 390px;
    position: absolute;
}

.app_img2 {
    width: 278px;
    height: 327px;
    margin-left: 100px;
    margin-top: 62px;
}


/* --------------- */


/* --------------- */

.faq {
    margin: 0 auto;
    margin-top: -30px;
    display: table;
    width: 71%;
    min-width: 910px;
}

.chat-example {
    background-color: #bde7e6;
    width: 910px;
    min-height: 376px;
    display: flex;
    padding: 30px 42px;
    font-size: 10px;
    justify-content: space-between;
    line-height: 1;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
    &__col {
        display: flex;
        flex-direction: column;
        margin-right: 21px;
        width: 100%;
        .chat-question {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            &__message {
                background-color: #46a6af;
                color: #ffffff;
                border-radius: 12px 12px 12px 0;
                padding: 9px 24px;
                max-width: 275px;
                min-height: 38px;
                display: flex;
                align-items: center;
                position: relative;
            }
        }
        .chat-answer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            &__message {
                background-color: #ffffff;
                color: #26364b;
                border-radius: 12px;
                padding: 22px;
                max-width: 275px;
                position: relative;
            }
        }
        &:last-child {
            margin-right: 0;
            .chat-question {
                justify-content: flex-end;
                &__message {
                    border-radius: 12px 12px 0 12px;
                }
            }
            .chat-answer {
                justify-content: flex-start;
            }
        }
    }
    &__row {
        margin-bottom: 22px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .chat-time {
        font-size: 7px;
        position: absolute;
        top: 100%;
        left: 100%;
        transform: translate(calc(-100% - 10px), calc(-100% - 4px));
        display: flex;
        .fa-check {
            margin-left: 3px;
        }
    }
}


/* ----------------------- */

.alphabet {
    display: flex;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 16px;
    max-width: 950px;
}

.alphabet-item {
    width: 100px;
    margin: 0 auto;
}

.alphabet-item-title {
    font-size: 22px;
    font-family: Arial;
    color: #49414c;
    line-height: 20px;
}

.alphabet-data-name {
    font-size: 14px;
    line-height: 9px;
    color: $main-dark-color;
}

.alphabet-data-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.alphabet-data-name span {
    color: #7eccc4;
}

.btn-home-alphabet-wrapper {
    max-width: 950px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.btn-home-alphabet {
    background-color: #03a8f5 !important;
    border-color: #039fe7 !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.btn-home-alphabet:hover {
    background-color: #039ae0 !important;
}

.about-slider {
    .carousel-control-next,
    .carousel-control-prev {
        width: 8%;
        display: none !important;
    }
    width: 910px;
    margin-top: 16px;
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        //fill: #546470;
        // height: 100px;
        // width: 100px;
        // outline: black;
        // background-size: 100%, 100%;
        // border-radius: 50%;
        //border: 1px solid black;
        // background-image: none;
        //opacity: unset;
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23546470' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23546470' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
    .carousel-indicators li {
        background-color: #546470;
    }
}

.carousel-item {
    img {
        width: 910px;
        height: 581px;
        object-fit: cover;
    }
    .carousel-opacity {
        padding: 30px 24px;
        background-color: rgba(255, 255, 255, 0.7);
        position: absolute;
        &-1 {
            top: 175px;
            left: 410px;
        }
        &-2 {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        p {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
            max-width: 371px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.features-block-main {
    width: 910px;
}

.features-block {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-around;
    .feature-container {
        cursor: pointer;
    }
}

@media screen and (max-width: 480px) {
    .desktop {
        display: none;
    }
}