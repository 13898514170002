@import '../../../variables.scss';
.medical-card-par {
    font-size: 12px;
    color: #49414c;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.indicators-modal-title {
    margin-bottom: 0px !important;
}

.indicators-form {
    .text-input-component input {
        min-width: 250px;
    }
}

.my-in-wrapper {
    max-width: 910px;
    width: 910px;
    margin: 8px auto 0 auto;
    display: flex;
    max-height: 100%;
    height: 100%;
    margin-bottom: $blocks-margin;
    .left-block {
        min-width: 250px;
        max-width: 25%;
        flex: 1;
        background-color: $blue-background;
        padding: 12px;
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .title {
            padding: 1rem;
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        .item {
            color: black;
            width: 100%;
            position: relative;
            text-decoration: none;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 12px;
            font-size: 13px;
            border-bottom: 1px solid #aaaaaa70;
            //align-items: flex-end;
            span {
                margin-left: 24px;
            }
            .arrow-left {
                display: none;
            }
            &:hover {
                cursor: pointer;
            }
        }
        .item:active,
        .item:hover,
        .item-active {
            background: #cedcf1;
            .arrow-left {
                display: block;
                position: absolute;
                right: 12px;
            }
        }
    }
    .right-block {
        min-width: 300px;
        flex: 2;
        border: 1px solid #d6d6d6;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .user_data_block {
            display: flex;
            width: 100%;
            margin-left: auto;
            background-color: #dee7f5;
            padding: 1px 100px 2px 8px;
            font-size: 13px;
            right: 0;
            img {
                width: 48px;
                height: 48px;
                margin-top: 2px;
                margin-bottom: 1px;
                margin-left: 4px;
                border-radius: 7px;
            }
            div {
                margin-left: auto;
                margin-right: 30px;
                margin-top: 4px;
                p {
                    display: flex;
                    margin-bottom: 4px;
                    font-weight: bold;
                    span {
                        margin-left: auto;
                        padding-left: 40px;
                        font-weight: normal;
                    }
                }
            }
        }
        .container-header {
            border: 1px solid #d6d6d6;
            border-bottom: none;
            padding: 16px;
            .title-action {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                align-items: center;
                font-size: 14px;
                .btns {
                    display: flex;
                    button {
                        margin-left: 16px;
                    }
                }
            }
            .title-info {
                padding: 1rem;
            }
        }
        .container-section-1 {
            border: 1px solid #d6d6d6;
            fill: #b8b8b8;
            flex: 1;
            display: flex;
            flex-direction: column;
            .chart-wrapper {
                flex: 1;
                display: flex;
                margin: 8px;
            }
            .tabs-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .MuiTab-root {
                    font-size: 13px;
                    min-width: unset;
                }
                .navigation-buttons {
                    align-self: center;
                    font-size: 13px;
                }
                .MuiTabs-indicator {
                    background-color: #0000001b;
                    height: 100%;
                }
            }
        }
        .apexcharts-toolbar {
            display: none;
        }
        .container-section-2 {
            flex: 1;
            display: flex;
            flex-direction: column;
            border: 1px solid #d6d6d6;
            border-top: none;
            padding: 0 8px;
            .all-data {
                flex: 1 1 1px;
                overflow-y: scroll;
                .infinite-scroll-component {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .single-data-item {
                        display: flex;
                        flex-direction: row;
                        padding: 4px 8px;
                        align-items: center;
                        font-size: 12px;
                        width: 50%;
                        .single-data-item-indicator-data {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            flex: 1;
                            .single-data-item-indicator-data-text {
                                margin-left: 16px;
                            }
                        }
                    }
                    .single-data-item {
                        border-bottom: solid 1px;
                        border-color: #ddd;
                    }
                    .single-data-item:last-child,
                    .single-data-item:nth-last-child(2) {
                        border-bottom: unset;
                        border-color: unset;
                    }
                    .single-data-item:nth-child(2n + 1) {
                        border-right: solid 1px;
                        border-color: #ddd;
                    }
                }
            }
        }
        .no-item-selected {
            margin-top: 100px;
            font-size: 0.9rem;
            padding: 30px;
            border: 1px solid $main-color;
            border-radius: 7px;
            text-align: center;
            width: 80%;
            margin: 200px auto;
            margin-bottom: 170px;
            color: $main-color;
        }
    }
}