@import '../../variables';

.image_label{
    position: relative;
    width: 120px !important;
    height: 120px !important;
    border-radius: 14px;
    overflow: hidden;
    cursor: pointer;
}

.image_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    .image_ {
        width: 120px !important;
        height: 120px !important;
        background-color: #d5d8da;
        object-fit: cover;
    }
    .input {
        display: none;
    }
    
    .delete-label {
        text-decoration: underline;
        color: $blue-main;
        font-size: 12px;
        cursor: pointer;
    }
}

.image_label:hover .image_hover { 
    display: flex;
}
.image_hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;

    .MuiSvgIcon-fontSizeLarge {
        font-size: 64px;
    }
}
