.vd-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    margin-right: 15px;
    padding: 8px;
    height: 31px;
    &-button {
        width: fit-content;
        padding: 0 36px;
    }
    &-vertical {
        margin-right: 0;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:last-child {
        margin-right: 0;
    }
    &:focus {
        outline: none;
    }
    &_success {
        background-color: #7eccc4;
        color: #fff;
        &:hover {
            background-color: #56948e;
        }
    }
    &_primary {
        background-color: #46a6af;
        color: #fff;
        &:hover {
            background-color: #4fb7c0;
        }
    }
    &_light {
        width: fit-content;
        padding: 0 20px;
        height: 27px;
        border-radius: 5px;
        background-color: #fff;
        font-size: 15px;
        color: #3b3b3b;
        a {
            color: #3b3b3b;
        }
    }
}

.vd-rounded-button {
    padding: 9px;
    border-radius: 50%;
    display: inline-flex;
    margin-right: 7px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &_primary {
        background-color: #ffe64e;
        color: #707070;
        &:hover {
            background-color: #efd74a;
        }
    }
}

.vd-dropdown {
    width: 100%;
    margin-top: 15px;
    &__title {
        height: 52px;
        background-color: #017698;
        color: #fff;
        padding: 0 30px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        justify-content: space-between;
        cursor: default;
        &:hover {
            background-color: #46a6af;
        }
        p {
            margin-bottom: 0 !important;
        }
    }
    &__drop {
        cursor: pointer;
    }
    &__item {
        padding: 40px 30px 60px 30px;
        font-size: 15px;
        animation: smoothDrop 1s linear forwards;
        &_title {
            word-spacing: 5px;
            border-bottom: 1px solid #868686;
            margin-bottom: 0;
            height: 35px;
            line-height: 35px;
            padding-left: 5px;
            cursor: pointer;
        }
        &_title.item-selected {
            font-weight: bold;
        }
        &_text {
            padding: 30px;
            animation: smoothDrop 1s linear forwards;
        }
    }
    &:first-child {
        margin-top: 0;
    }
}

@keyframes smoothDrop {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

.mini-dropdown {
    &__header {
        display: flex;
        cursor: pointer;
        p {
            margin-bottom: 0;
            font-size: 17px;
            font-weight: bold;
            color: #fff;
        }
        &_title {
            margin-right: 5px;
        }
        &_drop {}
    }
    &__items {
        position: absolute;
        &_item {
            margin-bottom: -7px;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: #7eccc4;
            }
        }
    }
}

.react-autosuggest__input {
    height: 33px;
}