@import '../../variables.scss';
.vacantions {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title-block {
        display: flex;
        width: 100%;
        max-width: 950px;
        padding: 0 $standart-padding;
        p {
            text-align: start !important;
        }
    }
    &__block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #eef2f8;
        width: 100%;
        max-width: 950px;
        padding: 32px;
        margin-bottom: 85px;
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;
    }
}