.breadcrumbs-wrapper {
    border-radius: 3px;
    display: flex;

    p {
        margin: 0;
    }

    a, p, span {
        text-transform: uppercase;
        margin-right: 10px;
        color: #4b424d;
        font-size: 12px;
    }
}
