@import "../../variables.scss";
.personal-cabinet-btn {
    background-color: $auth-button !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    margin-left: 8px;
    color: $blue-main !important;
    &:hover {
        background-color: $auth-button-hover !important;
        color: $blue-main !important;
    }
}

.modal-dialog {
    max-width: fit-content !important;
}

.modal-content {
    width: fit-content !important;
}

.modal-title {
    font-size: $medium-title-font-size !important;
    font-weight: bold !important;
    margin-bottom: 35px !important;
}

.modal-body {
    background-color: $modal-background-color;
    border-radius: 5px;
    padding: 40px 66px 40px 44px !important;
    &.login {
        width: 570px;
    }
    &.register {
        width: 723px;
    }
    &.step1 {
        width: 766px;
        padding-left: 85px !important;
        padding-right: 85px !important;
    }
}

.modal-custom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $modal-footer-margin-top;
    P {
        font-size: $medium-label-font-size !important;
        margin-bottom: 0;
    }
}

.modal-button-block {
    display: flex;
    justify-content: flex-end;
    button {
        margin-right: 22px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.modal-link {
    display: block;
    font-size: $medium-label-font-size;
    margin: 15px 0 25px 112px !important;
}

.modal-close {
    color: $close-color;
    font-size: 18px;
    position: absolute;
    left: 100%;
    top: 14px;
    transform: translate(calc(-100% - 17px), 0);
    cursor: pointer;
    &:hover {
        color: $close-hover-color;
    }
}

.doctor-or-patient-buttons-block {
    display: flex;
    flex-direction: column;
}

.btn-modal-window {
    background-color: $main-button !important;
    border-color: $main-button !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    font-weight: bold !important;
    min-width: 250px;
}

.btn-modal-window:hover {
    background-color: $main-button-hover !important;
}

.login-btn {
    background-color: #3ae22e !important;
    border-color: #3ae22e !important;
}

.login-btn:hover {
    background-color: #37d32c !important;
    border-color: #37d32c !important;
}

.auth-wrapper {
    display: flex;
    align-items: center;
    &__login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__lang {
        display: flex;
        align-items: flex-end;
        margin-right: 15px;
        .language {
            //font-size: 11px;
            color: #fff;
            cursor: pointer;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            &.current-lang {
                text-decoration: underline;
            }
        }
    }
}

.auth-error-wrapper {
    width: 100%;
    border: 1px solid $error-border-color;
    padding: 0 10px 9px 0;
    margin-bottom: 30px;
    border-radius: 1px;
    display: flex;
    img {
        display: block;
    }
    p {
        margin-bottom: 0;
        color: $error-color;
        font-size: 0.9rem;
        text-align: left;
        margin-top: 19px;
        margin-left: 5px;
    }
}

.message-text {
    display: flex;
    flex-direction: column;
    border: 1px solid #25a57a;
    margin: 0 auto;
    width: 93.7%;
    padding: 15px 20px;
    border-radius: 10px;
    p {
        color: #25a57a;
        margin: 0;
        text-align: center;
    }
}

.lang-block {
    display: flex;
    cursor: pointer;
    color: blue;
    .lang-link {
        margin-right: 8px;
        font-size: 0.8rem;
    }
    .lang-link-selected {
        text-decoration: underline;
    }
}