.checkbox-component-wrapper {
    display: flex;
    input {
        width: 15px;
        margin-top: 6px;
    }
    p {
        // outline: 1px solid red;
        padding-right: 10px;
    }
}
