@import '../../variables.scss';
.welcome-about-service {
    margin-top: -60px;
    color: #5b535e;
    font-size: 14px;
}

.welcome-main-block {
    width: 62.5%;
    margin: 40px auto;
    display: flex;
}

.welcome-desc-block {
    width: 50%;
}

.welcome-desc-title {
    font-size: 20px;
    font-weight: bold;
    color: #49414c;
    margin-top: 140px;
}

.welcome-desc-text {
    margin-top: 30px;
    color: #49414b;
    line-height: 20px;
    font-size: 0.9rem;
}

.welcome-desc-bg-image {
    display: inline-block;
    width: 42%;
    margin-left: auto;
}


/* ------------------------------- */

.medical-services-title {
    font-size: 20px;
    text-align: center;
    color: #49414c;
    margin-top: 30px;
}

.medical-services {
    display: flex;
    flex-wrap: wrap;
    width: 820px;
    margin: 30px auto;
}

.medical-services {
    display: flex;
    .medical-services-item {
        text-align: center;
        width: 48.5%;
        height: 270px;
        border-radius: 23px;
        h1 {
            font-size: 1.1rem;
            margin-bottom: 23px;
            margin-top: 110px;
            font-weight: normal;
        }
        .blue-btn {
            margin: 0 auto;
            background-color: white !important;
            color: black !important;
            border-radius: 3px !important;
            &:hover {
                background-color: rgb(241, 241, 241) !important;
            }
        }
        &:nth-of-type(1) {
            background-color: #fff59e;
            margin-bottom: 20px;
        }
        &:nth-of-type(2) {
            margin-left: auto;
            background-color: #b3e0dc;
            margin-bottom: 20px;
        }
        &:nth-of-type(3) {
            background-color: #7eccc4;
        }
        &:nth-of-type(4) {
            margin-left: auto;
            background-color: #83d6fc;
        }
    }
}


/* --------------------------------- */

.counter-block-wrapper {
    width: 62.5%;
    margin: 0 auto;
}

.counter-block {
    margin-top: 30px;
    background-color: #eef2f8;
    padding: 39px 130px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
}

.counter-item {
    max-width: 120px;
}

.counter-title {
    color: $main-dark-color;
    font-size: 29px;
    text-align: center;
    line-height: 1;
    margin-bottom: 7px;
}

.counter-desc {
    text-align: center;
    font-size: 11px;
    margin-bottom: 0;
    line-height: 1;
}


/* --------------- */


/* --------------- */

.content-block {
    width: 910px;
}

.mobile-app-block {
    background-color: #eef2f8;
    padding: 50px 30px 0 30px;
    margin-top: 30px;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.1);
}

.app_icon {
    width: 75px;
    height: 75px;
}

.mobile-app-title {
    color: $main-dark-color;
    font-size: 30px;
    margin-top: 10px;
}

.mobile-app-desc {
    font-size: 16px;
    margin-top: -10px;
}

.qr-code-img {
    width: 130px;
    height: 130px;
    margin-top: 20px;
    float: left;
}

.app-images-block {
    display: flex;
    margin-top: 56px;
}

.app-store {
    margin-left: 38px;
    width: 110px;
    height: 45px;
    cursor: pointer;
}

.google-play {
    margin-left: 31px;
    width: 150px;
    height: 60px;
    cursor: pointer;
}


/* --------------- */

.mobile-app-right {
    width: 400px;
    margin-left: auto;
    margin-right: 20px;
}

.app_img1 {
    width: 259px;
    height: 382px;
    position: absolute;
}

.app_img2 {
    width: 248px;
    height: 292px;
    margin-left: 175px;
    margin-top: 90px;
}


/* --------------- */


/* --------------- */

.faq {
    margin: 0 auto;
    margin-top: -30px;
    display: table;
    width: 100%;
}

.alphabet {
    display: flex;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 16px;
    width: 90%;
}

.alphabet-item {
    width: 100px;
    margin: 0 auto;
    display: inline-block;
}

.alphabet-item-title {
    font-size: 22px;
    font-family: Arial;
    color: #49414c;
    line-height: 20px;
}

.alphabet-data-name {
    font-size: 14px;
    line-height: 9px;
    color: $main-dark-color;
}

.alphabet-data-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.alphabet-data-name span {
    color: #7eccc4;
}

.btn-welcome-alphabet-wrapper {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 6%;
    text-align: right;
}

.btn-welcome-alphabet {
    background-color: #03a8f5 !important;
    border-color: #039fe7 !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.btn-welcome-alphabet:hover {
    background-color: #039ae0 !important;
}

.welcome-main-wrapper {
    max-width: 1450px;
    margin: 0 auto;
}

.medium-title {
    font-size: $medium-title-font-size;
    font-weight: bold;
}

.reviews-block {
    .big-title {
        font-size: $medium-title-font-size;
    }
}