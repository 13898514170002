.home-new-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    .home-new-header {
        display: flex;
        height: 60px;

        .left-block {
            display: flex;

            .left-sub-1 {
                a {
                    display: flex;
                    padding: 15px;
                    width: 173px;

                    p {
                        margin: 3px 0 0 12px;
                        color: #0279bd;
                        font-weight: bold;
                        font-size: 17px;
                    }
                }
            }

            .separator {
                width: 2px;
                background-color: #0279bd;
                height: 43px;
                align-self: center;
            }

            .left-sub-2 {
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 5px 10px;

                    img {
                        align-self: center;
                    }

                    p {
                        margin: 2px 0 0 0;
                        color: #0279bd;
                        font-size: 13px;

                        span {
                            color: black;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .right-block {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            align-items: center;
            padding: 15px;

            .lang-block-home-new {
                display: flex;
                margin-right: 70px;

                a {
                    font-size: 11px;
                    color: #0279bd;

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-right: 7px;
                    }
                }
            }

            .help-links {
                display: flex;
                margin-right: 17px;

                a {
                    font-size: 11px;
                    color: #0279bd;

                    &:nth-child(1) {
                        margin-right: 10px;
                    }
                }
            }

            .access-to-health-card-btn {
                color: #0279bd;
                font-size: 11px;
                background-color: #eef2f8;
                border-radius: 5px;
                border: none;
                padding: 5px 8px;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
                outline: none;
                transition: 0.3s;

                &:hover {
                    filter: brightness(97%);
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    .welcome-block {
        height: 594px;
        background: #a9d8e0 url("./../../images/home-new/main-header-bg.png") no-repeat;
        background-size: 1000px 594px;

        .health-icon-block {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 45px;
            margin: 0 auto;

            p {
                color: #0279bd;
                font-size: 24px;
                font-weight: bold;
                margin: 5px 0 0 0;
            }
        }

        .desc-and-button {
            p {
                font-weight: bold;
                font-size: 15px;
                width: 600px;
                margin: 14px auto;
                text-align: center;
                color: #353240;
                line-height: 16px;
            }

            button {
                display: block;
                margin: 28px auto;
                border: none;
                font-size: 11px;
                font-weight: bold;
                color: #fff;
                background-color: #03a8f5;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
                outline: none;
                transition: 0.3s;
                padding: 6px 13px 7px 13px;
                border-radius: 5px;

                &:hover {
                    filter: brightness(95%);
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                }
            }
        }

        .apps-block {
            display: flex;
            flex-direction: column;
            width: 19%;
            align-items: center;
            margin-left: auto;
            margin-top: 195px;

            a {
                &:nth-of-type(2) {
                    margin-top: 13px;
                }
            }
        }
    }

    .features-list-main-block {
        background: #e1a87e url("./../../images/home-new/features-list-bg.png") repeat;
        color: #353240;

        h2 {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            padding-top: 30px;
        }
        h3 {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            padding: 10px 0 40px 0;
        }

        .features-list-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            div {
                display: flex;
                width: 35%;
                align-items: flex-start;
                margin-bottom: 20px;

                p {
                    font-size: 11px;
                    margin: 3px 0 0 8px;
                }
            }
        }
    }

    .app-features-block {
        height: 433px;
        background: #d3e3e3 url("./../../images/home-new/app-features.png") no-repeat;
        background-size: 1000px 433px;
        color: #353240;

        .app-description {
            width: 32%;
            margin: 0 auto;
            padding-top: 75px;
            padding-left: 10px;

            h2 {
                font-weight: bold;
                font-size: 17px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-top: 25px;
            }
            button {
                display: block;
                margin-top: 22px;
                border: none;
                font-size: 11px;
                font-weight: bold;
                color: #fff;
                background-color: #03a8f5;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
                outline: none;
                transition: 0.3s;
                padding: 7px 25px 8px 25px;
                border-radius: 5px;

                &:hover {
                    filter: brightness(95%);
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }

    .share-your-card-block {
        height: 433px;
        background: #adcf85 url("./../../images/home-new/share-your-card-bg.png") no-repeat;
        background-size: 1000px 434px;
        color: #353240;

        .share-description {
            width: 32%;
            margin-left: 150px;
            padding-top: 100px;

            h2 {
                font-weight: bold;
                font-size: 17px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-top: 25px;
            }
            button {
                display: block;
                margin-top: 45px;
                border: none;
                font-size: 11px;
                font-weight: bold;
                color: #fff;
                background-color: #03a8f5;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
                outline: none;
                transition: 0.3s;
                padding: 7px 25px 8px 25px;
                border-radius: 5px;

                &:hover {
                    filter: brightness(95%);
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }

    .make-an-appointment-block {
        height: 433px;
        background: #a4d8f0 url("./../../images/home-new/make-an-appointment-bg.jpg") no-repeat;
        background-size: 1000px 433px;
        color: #353240;

        .make-an-appointment-description {
            width: 40%;
            margin: 0 auto;
            padding-top: 130px;
            padding-left: 120px;

            h2 {
                font-weight: bold;
                font-size: 17px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-top: 25px;
            }
        }
    }

    .take-care-of-your-family-block {
        height: 431px;
        background: #fefefe url("./../../images/home-new/take-care-of-your-family-bg.jpg") no-repeat;
        background-size: 1000px 431px;
        color: #353240;
        display: flex;

        .take-care-of-your-family-description {
            width: 25%;
            margin: 150px 0 0 160px;

            h2 {
                font-weight: bold;
                font-size: 17px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-top: 25px;
            }
        }
    }

    .contact-your-doctor-block {
        height: 434px;
        background: #6ec0b1 url("./../../images/home-new/contact-your-doctor-bg.jpg") no-repeat;
        background-size: 1000px 434px;
        color: #353240;
        display: flex;

        .contact-your-doctor-description {
            width: 30%;
            margin: 130px auto;
            padding-left: 50px;

            h2 {
                font-weight: bold;
                font-size: 17px;
            }
            p {
                font-size: 12px;
                font-weight: bold;
                margin-top: 25px;
            }
        }
    }

    .see-how-block {
        height: 437px;
        background: #6ec0b1 url("./../../images/home-new/see-how-bg.jpg") no-repeat;
        background-size: 1000px 437px;
        color: #353240;
        display: flex;
        flex-direction: column;

        h2 {
            font-weight: bold;
            font-size: 17px;
            margin-top: 30px;
            text-align: center;
        }

        .see-how-description {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 30px auto;
            width: 80%;

            .video-tutorial {
                width: 400px;
                height: 280px;
                background-color: #000;
            }

            .video-tutorial-desc {
                width: 35%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    font-size: 12px;
                    font-weight: bold;
                    margin: 0 0 0 20px;
                }

                button {
                    margin: 25px auto;
                    border: none;
                    font-size: 11px;
                    font-weight: bold;
                    color: #fff;
                    background-color: #03a8f5;
                    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
                    outline: none;
                    transition: 0.3s;
                    padding: 7px 15px 8px 15px;
                    border-radius: 5px;

                    &:hover {
                        filter: brightness(95%);
                        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }
    }

    .pre-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #353240;
        background: #c3e5e0 url(./../../images//home-new/pre-footer-bg.jpg) no-repeat;
        background-size: 1000px 488px;
        height: 488px;

        h2 {
            font-weight: bold;
            font-size: 17px;
            margin-top: 20px;
        }

        button {
            display: block;
            margin-top: 15px;
            border: none;
            font-size: 11px;
            font-weight: bold;
            color: #fff;
            background-color: #03a8f5;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
            outline: none;
            transition: 0.3s;
            padding: 7px 15px 8px 15px;
            border-radius: 5px;

            &:hover {
                filter: brightness(95%);
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
            }
        }

        p {
            font-size: 12px;
            font-weight: bold;
            margin: 0;

            &:first-of-type {
                margin: 20px 0 0 0;
            }
        }

        .app-markets-block {
            margin-top: 20px;
            a {
                &:first-of-type {
                    margin-right: 20px;
                }
            }
        }
    }

    .home-new-footer {
        height: 173px;
        display: flex;

        .home-new-footer-left {
            width: 420px;

            .logos {
                display: flex;
                justify-content: center;

                .logo-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 15px;

                    p {
                        color: #0279bd;
                        font-size: 15px;
                        font-weight: bold;
                        margin: 5px 0 0 0;
                    }
                }

                .logo-videodoctor {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 5px 10px;

                        img {
                            align-self: center;
                        }

                        p {
                            margin: 2px 0 0 0;
                            color: #0279bd;
                            font-size: 11px;
                            font-weight: bold;

                            span {
                                color: black;
                                font-size: 10px;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }

            .contacs-block {
                width: 72%;
                margin-left: auto;

                .call-us,
                .write-us {
                    display: flex;
                    margin-top: 7px;

                    p {
                        font-size: 10px;
                        margin: 0;
                    }
                    .numbers-list {
                        margin-left: 20px;

                        div {
                            img {
                                margin-right: 5px;
                            }
                            display: flex;
                        }
                    }

                    .emails-list {
                        margin-left: 23px;

                        div {
                            img {
                                margin-right: 5px;
                            }
                            display: flex;
                        }
                    }
                }
            }
        }

        .home-new-footer-right {
            width: 630px;
            background: url("./../../images/home-new/footer-bg.jpg") no-repeat;
            background-size: 630px 173px;
            padding: 15px 0 0 30px;
            display: flex;
            flex-direction: column;

            .navigation-links {
                a {
                    color: #fff;
                    font-weight: bold;
                    font-size: 11px;
                    margin-right: 10px;
                }
            }

            .ezgil-logotype {
                display: flex;
                align-items: flex-end;
                margin-top: 85px;

                img {
                    margin-right: 10px;
                }

                p {
                    color: #fff;
                    font-size: 10px;
                    margin: 0;
                }
            }
        }
    }
}
