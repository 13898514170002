@import '../../variables.scss';

.quick-access-wrapper {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 910px;
}

.qa-item-wrapper {
    width: 270px;
    margin: 0 auto;
    display: flex;
}

/* .qa-item-wrapper:hover {
    cursor: pointer;
    text-decoration: underline;
} */

.qa-item-title {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 1px;
    line-height: 14px;
}

.qa-item-desc {
    font-size: 11px;
}

.qa-item-img {
    width: 48px;
    height: 48px;
}

.qa-item-img-block {
    margin-right: 10px;
    width: 48px;
    height: 48px;
    .cls-1 {
        fill: transparent !important;
    }
    .cls-2, .cls-3 {
        fill: $main-color;
    }
}