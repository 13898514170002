@import '../../variables.scss';
.doctor-user-access-wrapper-main {
    h1 {
        font-size: 1.3rem;
        text-align: center;
        margin: 20px 0;
    }
    .my-md-wrapper {
        margin: 0 auto;
        flex-wrap: wrap;
        padding: 40px 80px;
        .my-md-item {
            border: 1px solid #c1c4c8;
            padding: 15px;
            width: 360px;
            color: black;
            text-decoration: none;
            background-color: #f6f8fb;
            border-radius: 6px;
            margin-top: 40px;
            -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            &:hover {
                background-color: #c5e0ef !important;
            }
            .md-item-text-block {
                display: flex;
                .image_block {
                    height: 65px;
                    width: 65px;
                    margin-right: 40px;
                    img {
                        background-size: contain;
                        height: 65px;
                        width: 65px;
                        border-radius: 7px;
                    }
                }
                .desc_block {
                    font-size: 10px;
                    text-align: center;
                    p {
                        margin: 0;
                        margin-top: 4px;
                        color: #3a3535;
                        &:nth-of-type(2) {
                            font-size: 13px;
                            color: $main-dark-color;
                            margin-top: 10px;
                        }
                    }
                }
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin-top: 0;
            }
            &:nth-of-type(even) {
                margin-left: auto;
            }
            &:hover {
                cursor: pointer;
                background-color: #e3f7ff;
            }
        }
        .search-patient-by-name-block {
            height: 60px;
            background-color: red;
            display: flex;
            color: #26364b;
            .left {
                width: 35%;
                background-color: #eef2f8;
                display: flex;
                img {
                    width: 65px;
                    height: 65px;
                    margin-right: 15px;
                }
                div {
                    p {
                        margin-bottom: 0;
                        font-size: 0.7rem;
                        margin-top: -3px;
                        &:first-of-type {
                            font-weight: bold;
                        }
                        &:last-of-type {
                            color: $main-dark-color;
                        }
                    }
                }
            }
            .right {
                width: 65%;
                background-color: #dee7f5;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                .regular-text {
                    // width: 20%;
                    margin: 0;
                    text-align: center;
                }
                .form-control-sm {
                    width: 60%;
                }
            }
        }
    }
}

.doctor-to-patient-access-tab {
    border: 1px solid rgba(0, 0, 0, 0.16);
    width: 100%;
    font-size: 0.8rem;
    tr,
    th,
    td {
        border: 1px solid rgba(0, 0, 0, 0.16);
        padding: 10px;
    }
    th.centered {
        text-align: center;
    }
    tr.patient:hover {
        background-color: rgb(238, 238, 238);
        cursor: pointer;
        color: rgb(53, 53, 53);
    }
}