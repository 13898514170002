@import '../../variables.scss';
.header {
    height: 141px;
    background-color: #46a6af;
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper {
        width: 910px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_logo-block {
            display: flex;
            &_logo {
                width: 183px;
                height: 81px;
            }
            &_jci {
                width: 76px;
                height: 66px;
                margin-left: 24px;
                margin-top: 12px;
            }
        }
    }
}

.header-title {
    font-size: 26px;
    font-family: Arial;
    color: $main-dark-color;
    margin: 25px 0 0 12px;
}

#dropdown-basic {
    text-decoration: none;
    margin-right: 25px;
    color: black;
    font-size: 14px;
}

.header-phone {
    margin-top: 7px;
    font-size: 14px;
}

.big-title {
    font-size: $medium-title-font-size;
    font-family: Arial;
    font-weight: 600;
    color: #49414C;
    text-align: center;
    padding: 0 0 0 0;
}

.small-title {
    font-size: 18px;
    font-family: Arial;
    color: #49414C;
    text-align: center;
    margin: 40px 0 8px 0;
}