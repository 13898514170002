@import '../../variables.scss';
.auth-form {
    &-flex {
        display: flex;
        flex-direction: column;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__col {
        width: 288px;
    }
}