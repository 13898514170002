@import './variables';
@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Thin'), local('ClearSans-Thin'), url('./fonts/clearsansthin.woff2') format('woff2'), url('./fonts/clearsansthin.woff') format('woff'), url('./fonts/clearsansthin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Light'), local('ClearSans-Light'), url('./fonts/clearsanslight.woff2') format('woff2'), url('./fonts/clearsanslight.woff') format('woff'), url('./fonts/clearsanslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans'), local('ClearSans'), url('./fonts/clearsans.woff2') format('woff2'), url('./fonts/clearsans.woff') format('woff'), url('./fonts/clearsans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Italic'), local('ClearSans-Italic'), url('./fonts/clearsansitalic.woff2') format('woff2'), url('./fonts/clearsansitalic.woff') format('woff'), url('./fonts/clearsansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Medium'), local('ClearSans-Medium'), url('./fonts/clearsansmedium.woff2') format('woff2'), url('./fonts/clearsansmedium.woff') format('woff'), url('./fonts/clearsansmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Medium Italic'), local('ClearSans-MediumItalic'), url('./fonts/clearsansmediumitalic.woff2') format('woff2'), url('./fonts/clearsansmediumitalic.woff') format('woff'), url('./fonts/clearsansmediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Bold'), local('ClearSans-Bold'), url('./fonts/clearsansbold.woff2') format('woff2'), url('./fonts/clearsansbold.woff') format('woff'), url('./fonts/clearsansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Clear Sans';
    src: local('Clear Sans Bold Italic'), local('ClearSans-BoldItalic'), url('./fonts/clearsansbolditalic.woff2') format('woff2'), url('./fonts/clearsansbolditalic.woff') format('woff'), url('./fonts/clearsansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

body {
    //font-family: 'Clear Sans' !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.btn {
    font-size: $medium-label-font-size !important;
    border-radius: 5px;
}

.btn-light {
    color: $light-button-text !important;
    a {
        color: $light-button-text;
        line-height: 20px;
    }
    a:hover {
        text-decoration: unset;
        color: $main-dark-color-hover;
    }
}

.blue-btn {
    background-color: $button-blue !important;
    color: white !important;
    padding: 0 30px !important;
    border-radius: 7px !important;
    border: none !important;
    height: 35px;
    line-height: 35px !important;
    &:hover {
        background-color: $button-blue-hover !important;
        cursor: pointer !important;
    }
    -webkit-box-shadow: 0px 1px 4px 0px #00000033 !important;
    -moz-box-shadow: 0px 1px 4px 0px #00000033 !important;
    box-shadow: 0px 1px 4px 0px #00000033 !important;
}

.dark {
    background-color: $main-dark-color !important;
    color: #fff;
}

a {
    color: $main-dark-color;
    &:hover {
        color: $main-dark-color-hover;
    }
}

.blue-background {
    background-color: $blue-background !important;
}

.standart-margin-bottom {
    margin-bottom: $blocks-margin;
}

.modal-content {
    background-color: $modal-background-color;
    .modal-header {
        background-color: $modal-background-color;
    }
    .modal-body {
        background-color: $modal-background-color;
    }
    .modal-footer {
        background-color: $modal-background-color;
    }
    -webkit-box-shadow: 0px 1px 4px 0px #00000033 !important;
    -moz-box-shadow: 0px 1px 4px 0px #00000033 !important;
    box-shadow: 0px 1px 4px 0px #00000033 !important;
    &.dark {
        background-color: $button-dark-blue !important;
        &:hover {
            background-color: $button-dark-blue-hover !important;
        }
    }
    &.auth-button {
        height: $auth-button-height !important;
    }
    p {
        margin-bottom: 0;
        width: max-content;
    }
    
    &:disabled {
        pointer-events: none;
    }
}

.hide-price {
    display: none;
}

.vd-dropdown__item {
    font-size: $medium-label-font-size;
    padding: 20px 30px 60px 30px;
}

.vd-dropdown__title {
    font-size: 15px;
    height: 32px;
}

.react-autosuggest__suggestion {
    padding: 4px 12px;
    font-size: 12px;
    font-family: unset;
}

.react-autosuggest__section-title {
    font-size: $medium-label-font-size;
    font-family: unset;
}

.spinner-button {
    display: flex !important;
    flex-direction: row;
    .spinner-div {
        margin-left: -23px;
        margin-right: 4px;
    }
}

.bold {
    font-weight: bold;
}

ul.dashed {
    list-style-type: none;
    &>li {
        text-indent: -5px;
        &:before {
            content: "-";
            text-indent: -5px;
        }
    }
}

.MuiTooltip-popper {
    max-width: 160px;
}