.alphabet {
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    -webkit-column-width: 160px;
    -moz-column-width: 160px;
    column-width: 160px;
    display: block;
    max-height: 300px;
    overflow: hidden;
    position: relative;
    &-item {
        width: 150px;
    }
    &-data-name {
        line-height: normal;
    }
    &.opened {
        max-height: none;
        position: initial;
    }
}

.gradient {
    position: absolute;
    pointer-events: none;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 200px;
    background: url(data:image/svg+xml;base64,alotofcodehere);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(75%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
}