@import '../../variables.scss';
.footer {
    display: flex;
    &__left {
        width: 42%;
        min-width: 300px;
        background-color: $main-dark-color;
        display: flex;
        justify-content: flex-end;
        padding: 40px;
        &_container {
            display: flex;
            flex-direction: column;
        }
        &_logos {
            display: flex;
            &_logo {
                width: calc(365 * 51px / 162);
                height: 51px;
                margin-right: 15px;
                image-rendering: -webkit-optimize-contrast;
            }
            &_jci {
                width: 59px;
                height: 51px;
                image-rendering: -webkit-optimize-contrast;
            }
        }
        &_contacts {
            font-size: 12px;
            color: #fff;
            margin-top: 30px;
        }
    }
    &__right {
        width: 58%;
        min-width: 700px;
        background-color: $main-color;
        padding: 40px 20px 40px 100px;
        display: flex;
        color: #fff;
        &_container {
            display: flex;
        }
        &_col {
            margin-right: 20px;
            max-width: 135px;
            &:last-child {
                margin-right: 0;
            }
            h1 {
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 15px;
            }
            p {
                font-size: 11px;
                margin-bottom: 10px;
                color: #fff;
            }
        }
        &_ezgil {
            display: flex;
            align-items: flex-end;
            margin-left: -10px;
            // margin-bottom: 20px;
            img {
                width: 31px;
                height: 44px;
            }
            p {
                font-family: Arial, Helvetica, sans-serif;
                line-height: 1.2;
                font-size: 10px;
                margin-bottom: 0;
                margin-left: 8px;
            }
        }
        .p-as-link {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-color: #0056b3;
            }
        }
    }
}

.main-warning {
    display: flex;
    max-width: 940px;
    margin: 0 auto;
    padding: 20px 0;
    &__circle {
        font-size: 26px;
        border: 2px solid black;
        border-radius: 30px;
        width: 45px;
        height: 45px;
        padding-bottom: 2px;
        padding-left: 4px;
        margin-top: 13px;
        margin-bottom: 0;
        margin-left: 5px;
    }
    &__title {
        font-size: 13px;
        margin-top: 13px;
        margin-left: 15px;
        margin-bottom: 0;
    }
}