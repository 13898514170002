.personal-cabinet-patient-form_main {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    padding-top: 16px;
    ._row {
        display: flex;
        justify-content: space-between;
    }
    ._block {
        max-width: 400px;
    }
    .text-input-component {
        &__error {
            position: absolute;
            width: -moz-fit-content !important;
            width: fit-content !important;
            color: #f12626 !important;
            left: 100%;
            top: 100%;
            background: #eef2f8;
            transform: translate(calc(-100% - 10px), -50%);
        }
    }
}

.edit-mode {
    margin-bottom: 1px !important;
    padding-top: 8px !important;
    input {
        border: 1px solid #95989a !important;
        pointer-events: all !important;
    }
    .edit-mode input {
        border: 1px solid #95989a !important;
        pointer-events: all !important;
    }
    select {
        border: 1px solid #95989a !important;
        pointer-events: all !important;
        border-radius: 5px !important;
        height: 33px !important;
        padding-left: 8px !important;
    }
}