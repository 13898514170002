@import '../../variables.scss';
.filters {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: $blocks-margin;
}

.filters-wrapper {
    width: 910px;
    h1 {
        text-align: center;
        font-size: $medium-title-font-size;
    }
}

.extra-top-filter {
    padding-top: 32px;
}

.extra-bottom-filter {
    margin-bottom: $blocks-margin;
}

.dropdown-filters-wrapper {
    background-color: #eef2f8;
    padding: 32px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dropdown-filters {
        width: 100%;
        margin: 0 12px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        .react-autosuggest__input {
            font-family: unset;
            font-size: 13px;
            padding: 8px 16px;
        }
    }
    .btn {
        margin: 0 12px;
    }
}

.green-btn {
    background-color: #46a6af !important;
    border: none !important;
    .list-filters {
        background-color: #eef2f8;
        padding: 32px 12px;
        display: flex;
        flex-direction: column;
        &__default-filters {
            display: flex;
            flex-direction: row;
            justify-content: center;
            white-space: nowrap;
            flex: 1;
            label {
                align-self: center;
            }
            input {
                min-width: 30%;
            }
        }
        &__default-filters>* {
            margin: 0 12px 0 12px;
        }
        &__search {
            font-size: 13px !important;
            float: right;
        }
        &__search-btm {
            font-size: 13px !important;
            float: right;
            margin: 8px 0;
        }
    }
}

.list-filters {
    background-color: #eef2f8;
    padding: 32px 12px;
    display: flex;
    flex-direction: column;
    &__default-filters {
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: nowrap;
        flex: 1;
        label {
            align-self: center;
        }
        input {
            min-width: 30%;
        }
    }
    &__default-filters>* {
        margin: 0 12px 0 12px;
    }
    &__search {
        font-size: 13px !important;
        float: right;
    }
    &__search-btm {
        font-size: 13px !important;
        float: right;
        margin: 8px 0;
    }
}