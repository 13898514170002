@import '../../variables.scss';
.cabinet-wrapper {
    max-width: 910px;
    margin: $blocks-margin auto;
    .blue-btn {
        margin-left: 16px;
    }
    .MuiTabs-flexContainer {
        background-color: #ffff;
    }
    .MuiTab-textColorInherit.Mui-selected {
        opacity: 1;
        background-color: $blue-background;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        outline: 0;
    }
    .MuiBox-root {
        background-color: $blue-background;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
    }
    #simple-tabpanel-1 .MuiBox-root {
        border-top-left-radius: 24px;
    }
    .button:focus {
        outline: none;
    }
    .MuiTabs-indicator {
        background-color: $blue-background;
    }
    .bottom-block {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
        padding-right: 24px;
        padding-bottom: 16px;
        .show-more-btn {
            width: 140px;
            font-size: 13px;
            margin: unset !important;
        }
        .show-more-btn:first-child {
            margin-right: 1rem !important;
        }
    }
    .my-profile {
        margin: 0 auto;
        h2 {
            margin-top: 50px;
        }
        .features-block {
            background-color: $blue-background;
            min-height: 100px;
            padding: 32px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .item {
                color: black;
                display: flex;
                width: 48%;
                margin-top: 30px;
                img {
                    width: 85px;
                    height: 85px;
                }
                div {
                    margin-left: 10px;
                    h5 {
                        font-size: 1rem;
                    }
                    p {
                        font-size: 0.7rem;
                    }
                }
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    margin-top: 0;
                }
                &:nth-of-type(2) {
                    margin-right: 0;
                }
                &:nth-of-type(4) {
                    margin-right: 0;
                }
            }
        }
    }
}

.show-more-btn {
    width: 180px;
    text-align: center;
    padding: 6px 0;
    background-color: #03A8F5;
    color: white;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    margin: 3% 21% 50px auto;
}

.show-more-btn:hover {
    cursor: pointer;
    background-color: #25B3F5 !important;
}

.show-more-btn-title {
    margin: 0;
}