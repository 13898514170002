.team {
    display: flex;
    justify-content: center;

    &__block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #eef2f8;
        max-width: 950px;
        padding: 32px;
        margin-bottom: 85px;
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;
        > div {
            // max-width: 535px;
            line-height: 1;
            margin-bottom: 20px;
        }
        img {
            margin-bottom: 32px;
            width: 890px;
            height: 350px;
            object-fit: cover;
        }
        &__text {
            width: 100%;
        }
    }
}

.team-big-title {
    padding: 32px 0 20px 0;
    font-weight: bold;
}
