@import '../../variables.scss';
.dialogs-block {
    background-color: $modal-background-color;
    border-radius: 5px;
    .modal-header {
        border-bottom: unset;
        padding: 5px 10px;
    }
    .modal-body {
        background-color: unset;
        padding: 0 120px !important;
        font-size: 13px;
        font-weight: bold;
    }
    .modal-footer {
        border-top: unset;
        button {
            min-width: 90px;
            margin-top: 14px;
            &:nth-of-type(1) {
                margin-right: 20px;
            }
            &:nth-of-type(2) {
                background-color: $main-dark-color !important;
            }
        }
        padding: 0 0 25px 0;
        margin-top: 10px;
        justify-content: center;
    }
    .dialog-warning {
        margin-top: 20px;
        font-size: 11px;
    }
}