.file-component-wrapper {
    div {
        input {
            width: 95%;
            outline: none !important;
        }
        span {
            font-weight: bold;
            color: rgb(207, 0, 0);
            font-size: 1.5rem;
            &:hover {
                cursor: pointer;
                color: rgb(255, 0, 0);
            }
        }
    }
}
