@import '../../variables.scss';
.online-appointment-content {
    height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    .spinner-wrapper {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search-in-medical-card-wrapper {
        background-color: #eef2f8;
        .search-in-medical-card {
            max-width: 950px;
            margin: 0 auto;
            text-align: center;
            padding: 40px 0 60px 0;
            h1 {
                font-size: 1.5rem;
                color: #26364b;
                font-weight: bold;
            }
            input {
                margin-top: 20px;
                width: 550px;
                border-radius: 5px;
                outline: unset;
                border: none;
                padding-left: 8px;
                font-size: 0.8rem;
                height: 30px;
                background-color: #f6f8fb;
                -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }
    .online-appointment-main {
        // outline: 1px solid red;
        max-width: 1200px;
        max-height: 100%;
        height: 100%;
        margin: 0 auto;
        //padding: 35px;
        //padding-top: 0;
        h1 {
            text-align: center;
            font-size: 1.5rem;
            color: #26364b;
            font-weight: bold;
        }
        .chat-wrapper {
            max-width: 950px;
            margin: 0px auto 0 auto;
            display: flex;
            max-height: 100%;
            height: 100%;
            .left-block {
                min-width: 300px;
                max-width: 37%;
                flex: 1;
                background-color: #eef2f8;
                padding: 16px;
                max-height: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .my-user {
                    .avatar-block {
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        &:hover {
                            cursor: pointer;
                        }
                        ._img {
                            width: 40px;
                            height: 40px;
                            background-color: #d5d8da;
                            border-radius: 5px;
                            object-fit: cover;
                        }
                        .online-indicator {
                            width: 8px;
                            height: 8px;
                            background-color: #7eccc4;
                            border-radius: 50%;
                            margin-left: auto;
                            margin-top: -6px;
                            margin-right: -2px;
                        }
                    }
                    span {
                        display: inline-block;
                        vertical-align: bottom;
                        margin: 0 0 0 10px;
                        color: #26364b;
                        font-size: 15px;
                    }
                }
                .search-bar {
                    margin-top: 16px;
                    input {
                        width: 100%;
                        padding-left: 10px;
                        border: unset;
                        height: 28px;
                        border-radius: 8px;
                        font-size: 0.8rem;
                        outline: unset;
                        &:focus {
                            border: 1px solid #d6d6d6;
                        }
                    }
                }
                .features-block {
                    width: 100%;
                    margin-top: 16px;
                    border: 1px solid #707070;
                    padding: 15px 20px;
                    .item-wrapper {
                        margin: 0 auto;
                        &:hover {
                            outline: 1px solid #d6d6d6;
                            cursor: pointer;
                        }
                        div {
                            min-height: 30px;
                            img {
                                margin: 0 auto;
                                display: block;
                                object-fit: cover;
                            }
                        }
                        &:nth-of-type(2) {
                            img {
                                height: 21px;
                                width: 24px;
                            }
                        }
                        &:nth-of-type(3) {
                            img {
                                height: 23px;
                                width: 23px;
                            }
                        }
                        p {
                            font-size: 0.6rem;
                            margin-bottom: 0;
                        }
                    }
                }
                .my-consultations-wrapper {
                    margin-top: 16px;
                    margin-right: -16px;
                    max-height: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    p {
                        margin-bottom: 0;
                    }
                    .my-consultations-container {
                        padding-right: 12px;
                        margin-top: 8px;
                        max-height: 100%;
                        overflow-y: scroll;
                        flex: 1 1 1px;
                        .item {
                            background-color: white;
                            padding: 12px;
                            display: flex;
                            margin-top: 12px;
                            border: 1px solid rgba(0, 0, 0, 0.08);
                            -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                            -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                            &:first-of-type {
                                margin-top: 0;
                            }
                            &:last-of-type {
                                margin-bottom: 20px;
                            }
                            &:hover {
                                cursor: pointer;
                            }
                            img {
                                width: 53px;
                                height: 53px;
                                object-fit: cover;
                            }
                            .item-text-info {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                .first-info {
                                    flex: 1;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    .record-status {
                                        margin-left: 8px;
                                        align-self: flex-start;
                                        .record-status-tooltip {
                                            right: -8px;
                                        }
                                    }
                                }
                                a {
                                    color: $main-dark-color;
                                    margin-left: 7px;
                                    display: block;
                                    font-size: 0.6rem;
                                }
                                .doctor-name {
                                    font-size: 0.7rem;
                                    line-height: 0.9rem;
                                }
                                .time {
                                    margin-left: 7px;
                                    font-size: 0.6rem;
                                }
                            }
                        }
                        .item-selected {
                            background-color: #efefef;
                        }
                    }
                }
            }
            .right-block {
                min-width: 300px;
                flex: 2;
                border: 1px solid #707070;
                height: 100%;
                max-height: 100%;
                display: flex;
                flex-direction: column;
                .active-doctor {
                    display: flex;
                    padding: 18px 16px;
                    border-bottom: 1px solid #707070;
                    a {
                        font-size: 1.4rem;
                        color: $main-dark-color;
                        font-weight: bold;
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                    img {
                        width: 36px;
                        height: 36px;
                        margin-left: 10px;
                        object-fit: cover;
                        &:hover {
                            outline: 1px solid #d6d6d6;
                            cursor: pointer;
                        }
                    }
                    .item-information {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        .time {
                            font-size: 0.6rem;
                            margin-right: 8px;
                        }
                    }
                    .cancelation-items {
                        font-size: 0.8rem;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: space-between;
                        svg {
                            margin-right: 4px;
                        }
                        .approve-item {
                            color: #7eccc4;
                            cursor: pointer;
                        }
                        .cancel-item {
                            color: #03a8f5;
                            cursor: pointer;
                        }
                    }
                }
                .messages-wrapper {
                    padding: 16px;
                    height: 100%;
                    max-height: 100%;
                    display: flex;
                    flex-direction: column;
                    .messages-block-main {
                        padding-right: 16px;
                        padding-left: 16px;
                        border-radius: 8px;
                        background-color: #eef2f8;
                        max-height: 100%;
                        overflow-y: scroll;
                        flex: 1 1 1px;
                        display: flex;
                        flex-direction: column-reverse;
                        .no-item-selected {
                            font-size: 0.9rem;
                            padding: 30px;
                            border: 1px solid $main-color;
                            border-radius: 7px;
                            text-align: center;
                            width: 80%;
                            margin: 0 auto;
                            margin-bottom: 170px;
                            color: $main-color;
                        }
                        .infinite-scroll-component {
                            display: flex;
                            flex-direction: column-reverse;
                            padding-top: 16px;
                            .message-item-incoming {
                                display: flex;
                                margin-bottom: 15px;
                                ._img {
                                    width: 40px;
                                    height: 40px;
                                    align-self: flex-end;
                                    background-color: #d5d8da;
                                    border-radius: 5px;
                                    object-fit: cover;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                                div {
                                    background-color: white;
                                    padding: 8px 15px;
                                    border-radius: 13px;
                                    margin: 0 0 0 10px;
                                    border-bottom-left-radius: unset;
                                    display: flex;
                                    flex-direction: column;
                                    p {
                                        font-size: 0.8rem;
                                        max-width: 192px;
                                        margin-bottom: 0;
                                        img {
                                            max-width: 160px;
                                            width: 160px;
                                            object-fit: cover;
                                        }
                                    }
                                    .time {
                                        margin-top: 6px;
                                        align-self: flex-end;
                                        font-size: 0.6rem;
                                    }
                                }
                            }
                            .message-item-outgoing {
                                display: flex;
                                flex-direction: row-reverse;
                                margin-bottom: 15px;
                                ._img {
                                    width: 40px;
                                    height: 40px;
                                    align-self: flex-end;
                                    background-color: #d5d8da;
                                    border-radius: 5px;
                                    object-fit: cover;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                                div {
                                    background-color: #89bcd9;
                                    color: white;
                                    padding: 8px 15px;
                                    border-radius: 13px;
                                    margin: 0 10px 0 0;
                                    border-bottom-right-radius: unset;
                                    display: flex;
                                    flex-direction: column;
                                    p {
                                        font-size: 0.8rem;
                                        max-width: 192px;
                                        margin-bottom: 0;
                                        img {
                                            max-width: 160px;
                                            width: 160px;
                                            object-fit: cover;
                                        }
                                    }
                                    .time {
                                        margin-top: 6px;
                                        align-self: flex-end;
                                        font-size: 0.6rem;
                                    }
                                }
                            }
                            .notification {
                                background-color: #f8dd31;
                                width: 235px;
                                margin: 16px auto 0 auto;
                                display: flex;
                                padding: 7px 16px;
                                border-radius: 7px;
                                &:hover {
                                    cursor: pointer;
                                    background-color: #f1d832;
                                }
                                img {
                                    width: 12px;
                                    height: 8px;
                                    margin-top: 4px;
                                }
                                p {
                                    font-size: 0.7rem;
                                    margin-bottom: 0;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
                .write-message-block {
                    min-height: 100px;
                    display: flex;
                    padding: 0 16px 16px 16px;
                    textarea {
                        background-color: #eef2f8;
                        width: 100%;
                        height: 90px;
                        border: none;
                        border-radius: 10px;
                        padding: 10px 16px;
                        outline: unset;
                    }
                    textarea::placeholder {
                        color: black;
                    }
                    img {
                        width: 41px;
                        height: 41px;
                        align-self: flex-end;
                        margin-left: 8px;
                        &:first-of-type {
                            margin-left: 15px;
                        }
                        &:hover {
                            cursor: pointer;
                            outline: 1px solid #d6d6d6;
                        }
                    }
                }
                .wait-chat-block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    border: 1px solid #cecece;
                    color: #717171;
                    border-radius: 5px;
                    margin-bottom: 8px;
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }
        }
        .choose-doctor-by-specs-wrapper {
            margin: 0 auto;
            max-width: 950px;
            h1 {
                text-align: center;
                width: 100%;
                margin-top: 50px;
                padding-bottom: 10px;
            }
            .choose-doctor-by-specs {
                margin: 30px auto 0 auto;
                max-width: 950px;
                display: flex;
                flex-wrap: wrap;
                .item {
                    margin: 0 auto;
                    h2 {
                        font-size: 1.4rem;
                        color: #49414c;
                        line-height: 1.4rem;
                    }
                    div {
                        margin-top: 15px;
                        a {
                            display: block;
                            font-size: 0.8rem;
                            line-height: 1.4rem;
                            color: $main-dark-color;
                            &:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            }
                            span {
                                color: #7eccc4;
                            }
                        }
                    }
                }
            }
            .blue-btn {
                margin: 40px 0;
                margin-left: auto;
            }
        }
    }
    .video-container {
        background-color: black;
        position: relative;
        height: 100%;
        width: 100%;
    }
    .peer-video {
        z-index: 1;
        position: absolute;
        background-color: black;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }
    .self-video {
        z-index: 2;
        position: absolute;
        background-color: transparent;
        top: 16px;
        right: 16px;
        width: 20%;
        height: 20%;
    }
    .video-buttons {
        z-index: 3;
        position: absolute;
        background-color: transparent;
        margin-left: auto;
        margin-right: auto;
        bottom: 16px;
        left: calc(50% - 112px);
        button {
            border-radius: 50%;
            height: 48px;
            width: 48px;
            border-color: transparent;
            border-width: 0;
            margin-right: 8px;
        }
        .hang-up {
            background: #f44336;
            color: white;
        }
        .button-off {
            background-color: #49414c;
        }
    }
    .video-buttons button:last-child {
        margin-right: 0px;
        margin-left: 8px;
    }
}

.modal-online-appointment {
    background-color: #eef2f8;
    padding: 40px 30px !important;
    border-radius: 5px;
    text-align: center;
    width: 345px;
    margin: 0 auto;
    .avatar {
        width: 156px;
        margin: 0 auto;
        img {
            display: inline-block;
            margin: 0 auto;
            width: 156px;
            height: 156px;
        }
    }
    a {
        color: $main-dark-color;
        &:first-of-type {
            font-weight: bold;
            display: block;
            font-size: 1.1rem;
            margin-bottom: 10px;
            margin-top: 16px;
        }
        &:last-of-type {
            font-size: 0.7rem;
        }
    }
    .btn-block {
        margin-top: 16px;
        button {
            margin-right: 17px;
            border-radius: 50%;
            height: 48px;
            width: 48px;
            border-color: transparent;
            border-width: 0;
            margin-right: 8px;
            &:last-of-type {
                margin-right: 0;
            }
            &:hover {
                cursor: pointer;
                outline: 1px solid #d6d6d6;
            }
        }
        .hang-up {
            background: #f44336;
            color: white;
        }
    }
    .btn-modal-window {
        min-width: 10px;
    }
    .btn-modal-window-negative {
        background: #7eccc4 !important;
        background-color: #7eccc4 !important;
        border-color: #7eccc4 !important;
    }
}