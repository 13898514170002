@import "../../variables.scss";
.doctors-wrapper {
    // max-width: 1000px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 50px;
    padding-top: 10px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.doctors-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 908px;
    margin: 0 auto;
    padding-top: 16px;
    select {
        font-size: 13px;
        width: unset;
    }
    &__sorting {
        max-width: 200px;
        border: 0.5px solid $main-border-color;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    }
    &__date-range {
        display: flex;
        flex-direction: row;
        font-size: 13px;
        input {
            margin: 0 8px;
        }
    }
    &__type {
        border-radius: 4px;
        border: 0.5px solid $main-border-color;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        .btn-light.focus,
        .btn-light:focus {
            background-color: unset;
        }
        >.active {
            background-color: unset;
        }
        > :hover {
            background-color: unset;
        }
    }
}

.date-input {
    padding: 6px 8px;
    border-radius: 4px;
    border: 0.5px solid $main-border-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    color: #26364b;
    &:focus {
        border-radius: 4px;
        border: 0.5px solid $main-border-color;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        color: #26364b;
    }
}

input.date-input::-webkit-calendar-picker-indicator {
    opacity: 0.4;
}

.doctors-dropdown-filters {
    float: left;
}

.form-select {
    width: 20% !important;
}

.dropdown1 {
    margin-left: auto;
    margin-right: 15%;
}

.doctors-main-wrapper {
    background-color: $blue-background;
    padding-top: 1px;
    padding-bottom: 50px;
}

.doctors-main-item {
    margin: 16px auto;
    max-width: 908px;
    min-height: 350px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    &:first-child {
        margin-top: 78px;
    }
}


/* --------------- */

.doctor-info {
    display: flex;
    padding: 34px 25px;
    justify-content: space-between;
    width: 709px;
}

.doctor-info-block {
    width: 39%;
    padding: 30px;
    line-height: 18px;
    max-height: 420px;
    overflow: hidden;
    position: relative;
    &:after {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 25%);
        pointer-events: none;
    }
    &.full {
        max-height: unset !important;
        &:after {
            background: unset !important;
        }
    }
    .show_more_doctor_description {
        font-size: 10px;
        color: #37baf7;
        text-align: right;
        text-decoration: underline;
        margin-top: -8px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}

.regular-text {
    font-size: 11px !important;
    color: #818181;
    line-height: 13px;
}

.item-list-block {
    display: flex;
    flex-wrap: wrap;
}

.doctor-title-1 {
    font-size: 17px;
    font-weight: bold;
    color: #49414c;
    line-height: 17px;
}

.time-block {
    width: 39%;
}

.about-doctor-item {
    font-size: 13px;
    display: inline-block;
    color: #818181;
}

.doctor-title-2 {
    font-size: 13px;
    color: #49414c;
    font-weight: bold;
    line-height: 13px;
    margin-bottom: 10px;
    margin-top: 20px;
}


/* ----------------------------- */

.visit-type-button {
    text-align: center;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid rgb(219, 219, 219);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.visit-type-button:first-child {
    margin-right: 16px;
}

.visit-type-button:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.visit-buttons-block {
    display: flex;
    margin-bottom: 15px;
    .blue-btn {
        margin-right: 10px;
    }
}

.visit-type-button p {
    margin: 0 !important;
}


/* ----------------------------- */

.record-time-block {
    width: 39%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.time-buttons-block {
    margin-top: 30px;
    min-height: 110px;
}

.time-button {
    float: left;
    font-size: 10px;
    margin-right: 8px;
    margin-bottom: 4px;
    text-align: center;
    color: #707070;
    padding: 6px 8px;
    //background-color: #83d6fc;
    border-radius: 6px;
    border: 1px solid #c3c3c3;
    &:hover {
        color: #fff;
        cursor: pointer;
        background-color: #fae14a;
        border: 1px solid transparent;
    }
}

.time-button-selected {
    color: #fff;
    cursor: pointer;
    background-color: #fae14a;
}


/* --------------- */

.image-block {
    width: 250px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: rgba(84, 162, 210, 0.6) 0px 0px 0px 1000px inset;
    background: url("./../../images/item-bg-img.png") no-repeat;
    background-size: 250px;
    &__avatar {
        height: 275px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.doctor-default-img {
    width: 120px;
    margin-left: 25%;
    margin-top: 40px;
}

.doctor-rating-block {
    margin-top: 40px;
    border-top: 2px solid white;
    text-align: center;
    cursor: pointer;
}

.stars-block {
    margin-top: 10px;
}

.star-img {
    width: 22px;
    margin-right: 8px;
}

.star-img:hover {
    cursor: pointer;
}

.star-img:first-child {
    margin-left: 50px;
}

.reviews-count {
    color: white;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}


/* ---------------------------------- */

.pagination-doc {
    text-align: center;
}

.pagination-doc-btn {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
    padding-top: 7px;
    border-radius: 3px;
    margin-top: 60px;
    color: #707070;
}

.pagination-doc-btn:hover {
    background-color: #83d6fc;
    cursor: pointer;
    color: white;
}

.pagination-doc-btn.pagination-doc-btn-active {
    background-color: #83d6fc;
    color: white;
}

.pagination-doc-btn-active:hover {
    background-color: #78c3e6;
}

.delete-doctor-btn {
    font-size: 0.9rem;
    outline: none !important;
    border: none;
    background-color: rgb(130, 180, 80);
    border-radius: 5px;
    margin-left: auto;
    display: block;
    margin-right: 30px;
    margin-bottom: 15px;
    padding: 3px 10px 4px 10px;
    color: white;
    &:hover {
        background-color: rgb(151, 209, 93);
        cursor: pointer;
    }
}

.view-doctors-btn {
    background-color: rgb(3, 168, 245);
    &:hover {
        background-color: rgba(3, 168, 245, 0.8);
    }
}

.calendar {
    display: flex;
    justify-content: center;
    margin: $blocks-margin 0;
    &__container {
        // width: 80%;
        width: 1052px;
        overflow-x: scroll;
        background-color: $blue-background;
        display: flex;
        padding-top: 15px;
        &_left-side {
            width: 233px;
            padding: 0 8px;
            &_clinic_label {
                padding: 15px;
                padding-top: 2px;
                text-align: left;
                font-size: 15px;
                font-weight: bold;
            }
            &_clinic_input {
                width: 216.1px;
                height: 23.8px;
                margin: 0px 0px 12.3px 0.9px;
                padding: 7.1px 0px 5.7px 10px;
                border-radius: 5px;
                box-shadow: 0 2px 4px 0 #95989a;
                border: solid 0.5px #95989a;
                background-color: #ffffff;
                outline: none;
                font-size: 11px;
            }
            .list-group-item {
                font-size: 11px;
                cursor: pointer;
                padding: 0;
                div {
                    padding: .50rem 1rem;
                }
                span {
                    display: block;
                }
                .item_details {
                    display: inline;
                    justify-content: space-between;
                    padding-right: 6px;
                    color: #007bff;
                    margin-bottom: 0 !important;
                }
            }
            .list-group-item:hover {
                background-color: #e2e7f2;
            }
            .list-group-item__selected {
                background-color: #e2e7f2;
            }
            &_label {
                padding: 24px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
            }
            &_add {
                margin-top: 8px;
            }
            &_card {
                padding: 8px 12px 8px 16px;
                background-color: #fff;
                margin-top: 8px;
                font-size: 10px;
                &_header {
                    display: flex;
                    &_avatar {
                        padding-top: 9px;
                    }
                    &_info {
                        width: 100%;
                    }
                    .top,
                    .bottom {
                        display: flex;
                        flex-direction: column;
                        height: 50%;
                    }
                    .top {
                        justify-content: flex-end;
                        align-items: flex-end;
                        padding-top: 4px;
                        .cancel {
                            color: $main-dark-color;
                            cursor: pointer;
                        }
                        .accept {
                            color: #7eccc4;
                            cursor: pointer;
                        }
                    }
                    .bottom {
                        padding-left: 5px;
                        span {
                            font-weight: 600;
                            width: 100%;
                        }
                        .patient-name {
                            color: $main-dark-color;
                        }
                    }
                }
                &_details {
                    margin-top: 30px;
                    span {
                        font-weight: bold;
                    }
                    .round {
                        padding: 9px;
                        border-radius: 50%;
                        display: inline-flex;
                        background-color: #ffe64e;
                        margin-right: 7px;
                        color: #707070;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .icon-buttons {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
        &_empty {
            height: 40px;
        }
        &_right-side {
            width: calc(100% - 233px);
        }
        .react-calendar {
            border: none;
            font-size: 10px;
            &__month-view {
                padding: 0 20px 24px;
            }
            &__navigation {
                justify-content: center;
                button {
                    min-width: fit-content !important;
                }
                &__label {
                    max-width: 80px;
                    display: block;
                }
            }
            abbr[title] {
                text-decoration: none;
                font-weight: 800;
            }
        }
        .rbc-calendar {
            height: 1100px;
            .rbc-time-view {
                background-color: #fff;
            }
            .rbc-toolbar {
                font-size: 12px;
                padding: 0 10px;
                .rbc-btn-group {
                    border-radius: 5px;
                    box-shadow: 0px 1px 6px 0px #707070;
                }
                button {
                    height: 30px;
                    padding: 0 18px;
                    border-radius: 5px;
                    border: none;
                    background-color: #fff;
                    &:hover {
                        background-color: #e2e7f2 !important;
                    }
                    &:active {
                        outline: none;
                        background-color: #e2e7f2 !important;
                        box-shadow: none;
                    }
                    &:visited {
                        outline: none;
                        background-color: #e2e7f2 !important;
                        box-shadow: none;
                    }
                    &.rbc-active {
                        background-color: #e2e7f2 !important;
                        box-shadow: none;
                        &:focus {
                            outline: none;
                        }
                    }
                }
                &-label {
                    font-size: 15px;
                    font-weight: bold;
                }
            }
            .rbc-day-bg {
                background-color: #fff;
            }
        }
    }
    &_modal {
        width: 100%;
        display: flex;
        &_footer {
            display: flex;
            width: 100%;
        }
        .react-calendar {
            border: none;
            font-size: 10px;
            &__month-view {
                padding: 0 20px 24px;
            }
            &__navigation {
                justify-content: center;
                button {
                    min-width: fit-content !important;
                }
                &__label {
                    max-width: 80px;
                    display: block;
                }
            }
            abbr[title] {
                text-decoration: none;
                font-weight: 800;
            }
        }
    }
    .rbc-month-header {
        background-color: #fff;
    }
}

.change-day-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    width: 235px;
    color: #49414c;
    &__nav-button {
        background-color: #eef3ff;
        color: #49414c;
        outline: none !important;
        border: none;
        border-radius: 5px;
        font-size: 0.9rem;
        padding: 5px 10px;
        min-width: 35px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        &:hover {
            background-color: #d9e0ee;
        }
    }
    &__calendar-button {
        .react-calendar {
            position: absolute;
            top: 35px;
            left: 75px;
        }
        .calendar-button {
            color: #49414c;
            cursor: pointer;
        }
    }
    .left {
        svg {
            margin-top: 2px;
        }
    }
    .right {
        svg {
            margin-top: 2px;
        }
    }
}

.appointment-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.calendar-modal {
    .calendar-clock {
        color: #00cbde;
    }
    .react-time-picker {
        font-size: 14px;
        &__button {
            padding: 4px 2px 4px 0;
        }
        &__clock-button {
            svg {
                stroke: #00cbde;
            }
        }
        &__wrapper {
            margin-top: 15px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
        }
    }
    .calendar_modal_footer {
        justify-content: space-evenly;
    }
    &__time {
        display: flex;
        justify-content: space-between;
        padding: 8px;
    }
}

.time-picker {
    border: 1px solid $input-border-color;
    font-size: $input-medium-font-size;
    height: $input-height;
    outline: none;
    width: 120px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.6);
    padding-left: 12px;
    &.error {
        border: 1px solid $error-border-color;
    }
}

@media (min-width: 576px) {
    .calendar-modal {
        .modal-dialog {
            max-width: 400px !important;
        }
    }
}

.disabled-btn-appointment {
    background-color: rgb(167, 167, 167);
    font-size: 0.8rem;
    border-radius: 7px;
    color: white;
    border: none;
    padding: 3px 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.info_buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 4px;
    .cancel {
        color: #0279bd;
        cursor: pointer;
    }
    .accept {
        color: #7eccc4;
        cursor: pointer;
    }
}