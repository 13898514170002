.record-status {
    line-height: 1;
    position: relative;
    display: inline-block;

    .record-status-tooltip {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 4px 4px;
        font-size: 0.6rem;
        line-height: 1.2;

        /* Position the tooltip */
        position: absolute;
        z-index: 100;
    }

    &:hover .record-status-tooltip {
        visibility: visible;
    }
}